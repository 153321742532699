
<template>
  <div class="flex-grow-1 h-100 d-flex flex-column   position-relative
align-items-center">
    <div class=" d-flex flex-column flex-grow-1 h-100 rounded">

      <div class="container my-form  px-0">
        <div class="d-flex justify-content-between">
          <button v-if="plan && !mobileMode" class="border-0 p-1 bg-sec text-white special-font  my-1
            font-weight-bold rounded px-3 small float-right  mx-1" @click="back">
            <i class="fas fa-arrow-left"></i>
            back
          </button>
          <button v-if="!onlyForm" class="border-0 p-1 bg-darkblue text-white special-font  my-1 ml-auto
            font-weight-bold rounded px-3 small float-right  mx-1" @click="$emit('toggle-mobile')">
            switch to {{ mobileMode ? 'desktop' : 'mobile' }} view
          </button>

        </div>

      </div>
      <a ref="pdf" class="ml-auto d-none" download="report.pdf"></a>

      <full-form v-if="!mobileMode" style="z-index:1" :fdata="fdata" @generate-plan="handleGenerate"
        :loadingPlan="loadingPlan" @clear-plan="clearPlan" :options="{
          showHouseholdExpenses,
        }" @get-location="getLocation" @show-terms="showTheTerms" :plan="plan" :planData="planData"
        :formData="formData" @show-email-dialog="morePersonal" @show-access-report-popup="accessReport">
      </full-form>

      <div class="form-container d-flex flex-column   align-items-center mx-auto
       w-100 position-relative  overflow-hidden flex-grow-1
  " v-else id="capture">

        <div class="position-relative  w-100" v-show="mainForm">

          <div class="d-flex pt-1 align-items-center position-absolute w-100  " id="progress-container" style="z-index:4">
            <button class=" center-item btn btn-link p-1 ml-2 mb-1" style="left:0;" @click="$emit('show-nav')">
              <img src="/images/ham.svg" alt="" class="ham-icon">
              <!-- <i class="fas fa-bars mb-0">

                </i> -->
            </button>
            <page-indicator :sections="sections" :currentSection="currentSection" class="container pl-0 sec-container" />
          </div>

          <PictureProgress :sections="sections" :currentSection="currentSection" :currentPage="currentPage" :fdata="fdata"
            v-if="!isMobile || !typingName" />
          <div v-else class="my-5 ">
          </div>

          <button class="position-absolute bg-transparent text-info rounded
                border-info
            " style="left:0;bottom:0;opacity:0.05;z-index:32;" @click="goToGenerate(false)">
            <i class="fas fa-forward"></i>
          </button>


        </div>


        <div class="form-progress-container mb-2 mt-2  " v-if="mainForm">
          <div class="form-progress-bar" :style="progressStyle"></div>
        </div>

        <div class=" text-white w-100 mb-2 text-left p-0 d-flex py-3" style="background-color: var(--text-color)"
          v-if="!mainForm">
          <img src="/images/ham-white.svg" alt="" class="ham-icon" @click="$emit('show-nav')">
          <h5 class="ml-3 text-white mb-0">More Personalized Plans</h5>

        </div>


        <question-container :currentSection="currentSection" :currentPage="currentPage + 1"
          :previousPage="previousPage + 1" :sections="sections" @next="next" @endForm="morePersonal"
          @generate="handleGenerate" @back="back" :fdata="fdata" :plan="plan" :constraints="constraints"
          @error="notification.error" @show-access-report-popup="showAccessReportPopUp = true" @show-email-dialog="showEmailDialog = true">
          <template v-slot:a1>
            <question text="What is your name?" class="">
              What is your name?
            </question>

            <input type="text" class="form-text-input bg-transparent " placeholder="Your Name" v-model="fdata.name"
              @focus="fdata.name = ''; typingName = true" @blur="typingName = false" @input="nameChanged">

          </template>


          <template v-slot:a2>

            <question text="">
              What is your gender?
            </question>

            <choice-button class="mt-auto" :size="0.9" :value="fdata.gender" />

          </template>

          <template v-slot:a3>

            <question text="">
              Where do you live?
            </question>

            <div class="container mb-3">

              <div class="row row-cols-4 row-cols-xs-2 ">

                <button class="city-button col d-flex flex-column p-1
                            align-items-center  position-relative
                                 " :class="(fdata.city.selected == k) ? 'text-white bg-blue rounded' :
                                   'text-secondary bg-transparent'
                                   " v-for="(c, k) in fdata.city.options" :key="c.name" @click="selectCity(k)">


                  <img :src="`/images/cities/${k < 7 ? c.name : 'Other'}.svg`" alt="" class="city-image">

                  <p class="mb-0 text-center small">
                    <small>

                      {{ c.name }}
                    </small>
                  </p>

                </button>



              </div>

            </div>



            <!-- <next-button  @click="next"  v-if="false"/> -->


          </template>

          <template v-slot:a4>

            <question text="">
              How old are you? (age in years)
            </question>

            <slider label="years" class=" mt-auto" v-model="fdata.starting_age" :min="18" :autofocus="true" />

          </template>

          <template v-slot:a5>

            <question text="">
              Educational Qualifications?
            </question>

            <radio-button class="mt-auto" direction="column" :value="fdata.education" />

          </template>

          <template v-slot:a6>

            <question text="">
              Are you married?
            </question>

            <choice-button class="mt-auto" :value="fdata.marital_status" />

          </template>

          <template v-slot:a7>

            <question text="">
              When do you want to retire?
            </question>

            <slider class="mt-auto" :min="18" v-model="fdata.retirement_age" :value="fdata.education" :autofocus="true" />

          </template>

          <template v-slot:b1>

            <question>

              Is your spouse financially dependent on your income for
              the standard of living your family currently enjoys?
            </question>

            <radio-button class="mt-auto " :stretch="true" :value="fdata.spouse_dependent" :size="1.0" />

          </template>

          <template v-slot:b2>
            <question>
              How many children do you have and how old are they?
            </question>

            <div v-if="fdata.children.length == 0" class="my-auto flex-grow-1 d-flex flex-column justify-content-center ">
              <h5 class="text-bluegray">
                No kids
              </h5>
            </div>

            <div class="mt-auto d-flex flex-column " style="">

              <div v-if="fdata.children.length != 0">

                <slider v-for="(c, k) in fdata.children" v-model="fdata.children[k].age" :heading="(k + 1) + ''"
                  :key="k" />
              </div>

              <div class="d-flex flex-row justify-content-between mt-3">
                <span>
                  <button class="normal-button  mr-2
                                    font-weight-bold  py-1 lead" @click="fdata.children.push({ age: 5 })">
                    <!-- <i class="fas fa-plus mt-2"></i> -->
                    +
                  </button>
                  <button class="normal-button
                                    font-weight-bold py-1 lead" @click="fdata.children.pop()">
                    <!-- <i class="fas fa-minus mt-2"></i> -->
                    -
                  </button>

                </span>
                <button class="normal-button font-weight-bold " @click="fdata.children.splice(0, fdata.children.length)">
                  NO KIDS
                </button>
              </div>


            </div>
          </template>

          <template v-slot:b3>

            <question>
              Are your parents financially dependent on you? If yes, what are their ages?
            </question>

            <slider label="years" heading="Mom" class=" mt-auto" :min="18" v-model="fdata.mother_age"
              :active="fdata.mother_dependent" @toggle="fdata.mother_dependent = !fdata.mother_dependent" />
            <slider label="years" heading="Dad" class="" :min="18" v-model="fdata.father_age"
              :active="fdata.father_dependent" @toggle="fdata.father_dependent = !fdata.father_dependent" />

          </template>

          <template v-slot:c1>

            <question>
              What is your occupation?
            </question>

            <radio-button class="mt-auto" direction="column" :value="fdata.occupation_self" />


          </template>

          <template v-slot:c2>

            <question>
              How much do you earn from job / business in a year (pre-tax)?
            </question>
            <slider label="Lakhs" v-model="fdata.annual_income_self" :autofocus="true" />

          </template>

          <template #c3>
            <question>
              and what is your annual income from other sources (pre-tax)?
            </question>
            <slider label="Lakhs" v-model="fdata.annual_income_other" />

            <div v-if="showHouseholdExpenses">
              <question>
                What are your Annual Household Expenses?
              </question>
              <slider label="Lakhs" :max="100" :min="1" v-model="fdata.current_household_expenses" />
            </div>

          </template>


          <template v-slot:c4>

            <question>
              How about home?
            </question>
            <radio-button :value="fdata.home_owned" class="" />

            <question>
              Size of your home?
            </question>
            <slider label="sq. ft" :max="10000" :min="100" :step="100" v-model="fdata.home_area" />

          </template>


          <template #c5>
            <question>
              Do you have home loan?
            </question>
            <!-- <radio-button
                    :value="fdata.home_loan_availed"
                    :stretch="true" class=" " :size="1.2"
                /> -->
            <slider label="Lakhs" :max="500" :min="0" :step="5" v-model="fdata.home_loan_availed" />


            <question>
              Do you have any other loans?
            </question>
            <slider label="Lakhs" :max="100" :min="0" :step="1" v-model="fdata.other_loans_availed" />


          </template>

          <template #c6>

            <question>
              How much approximate financial assets do you have
              (includes equities, mutual funds, FDs/CDs, gold etc)?
            </question>
            <slider label="Lakhs" :max="500" :step="5" :autofocus="true" v-model="fdata.total_financial_assets" />
            <div v-if="isMarried">



            </div>

          </template>

          <template #c7>
            <question>
              Any real estate assets, if yes how much?
            </question>
            <slider label="Lakhs" :max="500" :step="5" v-model="fdata.total_real_estate_assets" />

            <question>
              Any assets earmarked for retirement, if yes how much?
            </question>
            <slider label="Lakhs" :max="500" :step="5" v-model="fdata.current_investment_for_retirement" />
          </template>

          <template #c8>
            <div v-if="isMarried">
              <question>
                What does your spouse do?
              </question>
              <radio-button :value="fdata.occupation_spouse" class="" :size="0.9" />

            </div>

          </template>

          <template #c9>
            <div v-if="!skipValues">
              <div class="d-flex flex-row justify-content-between mb-2">
                <question>
                  Incase you have any Financial Goal for <span class="font-weight-bold text-primary">Home</span>
                </question>
                <button type="button" class="btn btn-warning btn- px-2 font-weight-bold" @click="skipToGeneratePlan">
                  Skip all goals
                </button>
              </div>

              <question>
                Total Goal Amount
              </question>
              <slider label="Lakhs" class="mb-3" v-model="fdata.goals_home_cost" />
              <question>
                Amount already Saved
              </question>
              <slider label="Lakhs" class="mb-3" :max="fdata.goals_home_cost" v-model="fdata.goals_home_savings" />

              <!-- <dual-thumb-slider
                        :max=100
                        :value1="fdata.goals_home_cost"
                        :value2="fdata.goals_home_savings"
                        @update:value1="handleSliderValueChange('goals_home_cost', $event)" 
                        @update:value2="handleSliderValueChange('goals_home_savings', $event)"
                    /> -->

              <div class="d-flex justify-content-between">
                <question>
                  By which year do you want to achieve the goal?
                </question>
                <!-- <slider label="Years" class="mb-3" :max=40 v-model="fdata.goals_home_year" /> -->
                <Dropdown :max=40 v-model="fdata.goals_home_year" />
              </div>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn btn-warning btn-md px-4 font-weight-bold" @click="next">
                  SKIP GOAL
                </button>
               <!-- <button type="button" class="btn btn-md px-4 font-weight-bold" @click="next"
                  style="background-color: var(--green);">
                  SAVE & NEXT
                </button> -->
              </div>
            </div>

          </template>

          <template #c10>
            <div v-if="!skipValues">
              <div class="d-flex flex-row justify-content-between mb-2">
                <question>
                  Incase you have any Financial Goal for <span class="font-weight-bold text-primary">Kids
                    Education</span>
                </question>
                <button type="button" class="btn btn-warning btn-sm px-2 font-weight-bold" @click="skipToGeneratePlan">
                  Skip all goals
                </button>
              </div>

              <question v-if="!skipValues">
                Total Goal Amount
              </question>
              <slider label="Lakhs" class="mb-3" v-model="fdata.goals_education_cost" />
              <question>
                Amount already Saved
              </question>
              <slider label="Lakhs" class="mb-3" :max="fdata.goals_education_cost"
                v-model="fdata.goals_education_savings" />

              <div class="d-flex justify-content-between">
                <question>
                  By which year do you want to achieve the goal?
                </question>
                <!-- <slider label="Years" class="mb-3" :max=40 v-model="fdata.goals_education_year" /> -->
                <Dropdown :max=40 v-model="fdata.goals_education_year" />
              </div>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn btn-warning btn-md px-4 font-weight-bold" @click="next">
                  SKIP GOAL
                </button>
                <!--<button type="button" class="btn btn-md px-4 font-weight-bold" @click="next"
                  style="background-color: var(--green);">
                  SAVE & NEXT
                </button>-->
              </div>
            </div>

          </template>

          <template #c11>
            <div v-if="!skipValues">
              <div class="d-flex flex-row justify-content-between mb-2">
                <question>
                  Incase you have any Financial Goal for <span class="font-weight-bold text-primary">Kids
                    Marriage</span>
                </question>
                <button type="button" class="btn btn-warning btn-sm px-2 font-weight-bold" @click="skipToGeneratePlan">
                  Skip all goals
                </button>
              </div>

              <question>
                Total Goal Amount
              </question>
              <slider label="Lakhs" class="mb-3" v-model="fdata.goals_marriage_cost" />
              <question>
                Amount already Saved
              </question>
              <slider label="Lakhs" class="mb-3" :max="fdata.goals_marriage_cost"
                v-model="fdata.goals_marriage_savings" />

              <div class="d-flex justify-content-between">
                <question>
                  By which year do you want to achieve the goal?
                </question>
                <!-- <slider label="Years" class="mb-3" :max=40 v-model="fdata.goals_marriage_year" /> -->
                <Dropdown :max=40 v-model="fdata.goals_marriage_year" />
              </div>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn btn-warning btn-md px-4 font-weight-bold" @click="next">
                  SKIP GOAL
                </button>
               <!-- <button type="button" class="btn btn-md px-4 font-weight-bold" @click="next"
                  style="background-color: var(--green);">
                  SAVE & NEXT
                </button>-->
              </div>
            </div>

          </template>

          <template #c12>
            <div v-if="!skipValues">
              <div class="d-flex flex-row justify-content-between mb-2">
                <question>
                  Incase you have any Financial Goal for <span class="font-weight-bold text-primary">Vacation</span>
                </question>
                <button type="button" class="btn btn-warning btn-sm px-2 font-weight-bold" @click="skipToGeneratePlan">
                  Skip all goals
                </button>
              </div>

              <question>
                Total Goal Amount
              </question>
              <slider label="Lakhs" class="mb-3" v-model="fdata.goals_holiday_cost" />
              <question>
                Amount already Saved
              </question>
              <slider label="Lakhs" class="mb-3" :max="fdata.goals_holiday_cost" v-model="fdata.goals_holiday_savings" />

              <div class="d-flex justify-content-between">
                <question>
                  By which year do you want to achieve the goal?
                </question>
                <Dropdown :max=40 v-model="fdata.goals_holiday_year" />
              </div>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn btn-warning btn-md px-4 font-weight-bold" @click="next">
                  SKIP GOAL
                </button>
               <!-- <button type="button" class="btn btn-md px-4 font-weight-bold" @click="next"
                  style="background-color: var(--green);">
                  SAVE & NEXT
                </button> -->
              </div>
            </div>

          </template>



          <template #c13>
            <div class="text-left d-flex align-items-center mt-auto mb-3">
              <!-- <input class="form-checker" type="checkbox" value=""> -->

              <button class="mr-2 rounded  border border-info outline-0" @click="fdata.agree = !fdata.agree"
                :class="fdata.agree ? 'bg-blue' : 'bg-white'">
                <i class="fas fa-check text-white"></i>

              </button>


              <span>
                I agree to all
                <span class="ml-1 p-0 text-info cursor-pointer my-link" @click="showTheTerms">
                  Terms and Conditions
                </span>
              </span>


            </div>

          </template>

          <template #d1>
            <div v-if="plan" class="h-100 d-flex flex-column">


              <!-- <p>
                        {{plan}}
                    </p> -->
              <div>
                <plan-viewer :plan="planData" :formData="formData" @show-email-dialog="showEmailDialog = true"
                  @show-credit-popup="showCreditPopUp = true" @show-access-report-popup="showAccessReportPopUp = true" />
              </div>


            </div>
            <div v-else class="d-flex flex-column justify-content-center flex-grow-1">

              <!-- <i class="fas fa-circle-notch vloading lead "></i> -->
              <div class="d-flex  mx-auto align-items-center">

                <img src="/images/shield.png" class="vloading mb-3" alt="" style="width:60px;">
                <!-- <img src="/images/logo-text.png" alt="" style="width:100px;"> -->
              </div>

              <p class="text-bluegray">
                Hang on while we get you the personalized
                and comprehensive recommendation!
              </p>

            </div>


          </template>



        </question-container>

        <!-- <div>
            <button @click="currentSection=2;currentPage=sections[currentSection].pages-1;">
                Go to end (for testing purpose)
            </button>
        </div> -->


        <!-- <div class="d-flex flex-row">

            <div>
            <button @click="currentSection=4;currentPage=0;">
                Go to extra form
            </button>
        </div>

    </div> -->
      </div>

      <!-- <transition name="tfade"

     >
      <div class="position-absolute h-100 w-100 d-flex flex-column rounded my-overlay
      align-items-center
      "
        v-if="loadingLocation"
      >
        <div class="bg-white rounded shadow my-auto  mx-3 p-4 px-5">
                 <i class="fas fa-circle-notch loading lead position-relative"></i>
                 <p class="mt-3">fetching location</p>
        </div>

      </div>
    </transition> -->

      <Loading :show="loadingLocation" message="Fetching location..." />

      <Loading :show="sendingEmail" message=" Hang on while we email you the personalized
              and comprehensive recommendation!" />
     

      <!-- <transition name="tfade"> -->
      <Modal :show="showEmailDialog" @close="showEmailDialog = false">

        <div class="bg-white rounded shadow my-auto">

          <button class="btn ml-auto float-right" @click="hideEmailDialog">
            <i class="fas fa-times text-muted"></i>
          </button>

          <form @submit="sendEmail" action="">

            <div class=" py-2">
              <div class="mt-4 pt-2">
                <div class="rounded-complete  font-weight-bold py-1
                            bg-green my-text cursor-pointer hover-light
                            px-3 mb-2 mx-5 rounded text-uppercase" @click="skipAndDownload">
                  Download Report
                </div>
              </div>
              <p class="text-light-gray mb-1 small">-- or --</p>
              <div class="px-4  pb-2 flex-grow-1">
                <input type="text" class="form-text-input bg-transparent mb-2 " v-model="fdata.email"
                  placeholder="Email ID to send the report to">

              </div>

            </div>

            <input type="submit" value='EMAIL ME' class="bg-green rounded border-0 shadow-sm  p-3
                font-weight-bold my-text w-100 mt-auto " />



          </form>

        </div>

        <!-- </transition> -->
      </Modal>
      <Loading :show="downPDF" message=" Hang on while the personalized
      and comprehensive recommendation is being downloaded!" />

      <Modal :show="showTerms" @close="showTerms = false">
        <div class="d-flex rounded-extra flex-column ">
          <div class=" flex-grow-1 mx-2 d-flex flex-column  py-3 px-1">

            <h5 class="text-left mt-2 mb-4 my-text">Terms and Conditions</h5>

            <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-justify bg-light rounded">
              <p v-html="texts.form.terms" />

            </div>

          </div>

          <div class="w-100 d-flex flex-row justify-content-stretch p-0 button-group">
            <button type="button" class="p-3 fpink border-0 flex-grow-1 font-weight-bold" @click="showTerms = false">
              CANCEL
            </button>
            <button type="button" class="p-3 fblue border-0 flex-grow-1 font-weight-bold" data-dismiss="modal"
              @click="agree = true; showTerms = false;">
              AGREE
            </button>
          </div>
        </div>


      </Modal>
      <Modal :show="showCreditPopUp">
        <div class="d-flex rounded-extra flex-column ">
          <div class=" flex-grow-1 d-flex flex-column bg-pri ">
            <h5 class="text-center mt-3 mb-4 my-text text-white">Credits Balance</h5>
          </div>
          <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-center bg-light rounded mt-3" style="padding: 20px;">
            <h5> You have insufficient credits </h5>
            <div class="w-100 d-flex flex-row justify-content-stretch p-0 button-group">
              <button type="button" class="p-2 bg-green border-0 flex-grow-1 font-weight-bold mt-3" data-dismiss="modal"
                @click="$store.commit('setShowCreditScreen', true); showCreditPopUp = false;">
                Purchase
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal :show="showCreditBalPopUp">
        <div class="d-flex rounded-extra flex-column ">
          <div class=" flex-grow-1 d-flex flex-column bg-pri ">
            <h5 class="text-center mt-3 mb-4 my-text text-white">Credits Balance</h5>
          </div>
          <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-center bg-light rounded mt-3" style="padding: 20px;">
            <h5>You have used one credit and your credit balance is <span style="color: red; font-weight: bold">{{
              $store.state.user ? $store.state.user.credits :
              '' }}</span></h5>
            <div class="w-100 d-flex flex-row justify-content-stretch p-0 button-group">
              <button type="button" class="p-2 bg-green border-0 flex-grow-1 font-weight-bold mt-3" data-dismiss="modal"
                @click="showCreditBalPopUp = false;">
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        v-if="!this.isReportUnlocked() && !($store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString())"
        :show="showCreditLinkPopUp">
        <div class="d-flex rounded-extra flex-column ">
          <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-center bg-light rounded mt-3" style="padding: 20px;">
            <!-- <h5 v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString()"> You have been shared {{$store.state.creditLinkCredits}} credit(s) which allows to generate ProtectMeWell recommendation report(s) </h5> -->
            <h5>The link has expired, please contact your advisor for a fresh link or proceed for FREE need
              analysis of Term Insurance</h5>
            <div class="w-100 d-flex flex-row justify-content-stretch p-0 button-group">
              <button type="button" class="p-2 bg-green border-0 flex-grow-1 font-weight-bold mt-3" data-dismiss="modal"
                @click="showCreditLinkPopUp = false;">
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal :show="showAccessReportPopUp && !this.isReportUnlocked()">
        <div class="d-flex rounded-extra flex-column ">
          <div class="flex-grow-1   px-2 mb-2 pr-3 small text-muted
                    text-center bg-light rounded mt-3" style="padding: 20px;">
            <h5
              v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString() && !loadingAccessReport">
              You can access the report by clicking OK. Note that this report will be shared with the advisor
            </h5>
            <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 100px;margin: 0 auto;">
              <button
                v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString() && !loadingAccessReport"
                type="button" class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                @click="accessReport()">
                OK
              </button>
              <button
                v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString() && loadingAccessReport"
                type="button" class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri">
                <i class="fas fa-circle-notch vloading lead "></i>
              </button>
            </div>
            <h5 class="mt-3"
              v-if="$store.state.creditLinkCredits > 0 && $store.state.creditValidity > new Date().toISOString() && !loadingAccessReport">
              If you don't want to share the report with advisor then you can purchase it here or exit</h5>
            <!-- <h5 class="mt-3" v-else> The link has expired, please contact your advisor for a fresh link or you can purchase the report here or exit</h5> -->
            <div class="d-flex flex-row justify-content-stretch p-0 button-group" style="width: 250px;margin: 0 auto;">
              <button type="button" class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 bg-pri"
                @click="purchaseReport()">
                Purchase Report
              </button>
              <button type="button" class="p-2 text-white border-0 flex-grow-1 font-weight-bold mt-3 ml-3 bg-sec"
                data-dismiss="modal" @click="showAccessReportPopUp = false;">
                Exit
              </button>
            </div>
          </div>
        </div>
      </Modal>



    </div>

  </div>
</template>

<script type='text/javascript'>
(function () { var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0]; s1.async = true; s1.src = 'https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset = 'UTF-8'; s1.setAttribute('crossorigin', '*'); s0.parentNode.insertBefore(s1, s0); })(); </script>

<script>
import ChoiceButton from '../components/FormItems/ChoiceButton'
import RadioButton from '../components/FormItems/RadioButton'
import QuestionContainer from '../components/FormItems/QuestionContainer'
import Question from '../components/FormItems/Question'
import PageIndicator from "../components/FormItems/PageIndicator"
import Slider from "../components/input/Slider"
import Dropdown from "../components/input/Dropdown"
import Loading from '../components/FormItems/Loading'
import PictureProgress from '../components/special/PictureProgress'
import PlanViewer from '../components/special/PlanViewer'
import FullForm from '../components/FormItems/FullForm'
import Modal from '../components/common/Modal'
import texts from '../texts'
import qs from 'querystring'

function Options({names,values,colors,initial}){
    const options = []
    for(let i=0;i<names.length;i+=1){
        options.push({
            name:names[i],
            color:(colors?colors[i]:null),
            value:(values?values[i]:names[i]),
        })
    }

    return {
        selected: (initial||0),
        options
    }
}

function YesNo(initial){
    return {
        selected: initial || 0,
        options: [
            {name:'Yes',color:'green',value:'Y'},
            {name:'No',color:'red',value:'N'}
        ]
    }
}

export default {
    components:{
        PageIndicator, Question, Slider, Dropdown,
        QuestionContainer,
        Loading,
        RadioButton ,ChoiceButton,
        PictureProgress, PlanViewer,
        FullForm,
        Modal,
    },
    props:{
        mobileMode:{type:Boolean},
        onlyForm:{type:Boolean},
    },
    data(){

        const notification = this.$notification

        return { 
            loadingPlan:false,
            rec_pdf_Url :  'get_pdf_url',
            recUrl :  'get_recommendation',
            email_pdf_Url:'email_pdf',
            emailUrl:  'send_email',
            showDrawer:false,
            showTerms:false,
            currentSection:0,
            currentPage:0,
            previousPage:0,
            navButtons:['home','about','form','contact'].map(nb => {return{name:nb,}}),
            errors:[],
            loadingLocation: false,
            sendingEmail:false,
            otp:null,
            showEmailDialog:false,
            typingName:false,
            // screenshot:null,

            imgDownload:false,
            texts,
            notification,
            showPopUp:true,
            waitTime:6000,
            downPDF:false,
            devmode:false,
            showCreditPopUp: false,
            showCreditBalPopUp: false,
            isLoggedIn: false,
            isShareLinkClicked: false,
            showAccessReportPopUp: false,
            loadingAccessReport: false,
            skipValues: false,

            isReportAllowed(){
                const reportsAllowed = this.$store.state.isRetail
                return reportsAllowed
            },
            isReportUnlocked() {
                return this.$store.getters.allow_reports
            },
            sections:[
                {
                    title:'Personal Details',
                    pages:7,
                },
                {
                    title:'Family Details',
                    pages:3,
                },
                {
                    title:'Financial Details',
                    pages:13,
                },
                {
                    title:'Personalized plans',
                    pages:1
                },
                {
                    title:'Personal Details',
                    pages:7
                },
                // {
                //     title:'More Personalized Details',
                //     pages:5
                // },

            ],
            fdata2:{
                spouse_age:31,
                spouse_education:
                    Options({
                        names:['Less than bachelors','bachelors','Masters and above'],
                        values:['C','G','M'],
                        colors:['blue','text-color','blue']}
                    )
                ,
                financial_assest_banks:2,
                financial_assest_equity:2,
                income_rental:3,
                income_other:4,
                expenses_rent:40,
                expenses_educational:26,
                expenses_utility:34,
                loan_car:23,
                loan_home:32,
                loan_educational:32,

            },
            fdata:{
                    agree:false,
                    name:'Jane Doe',
                    email:'',
                    mobile:'',
                    gender:{
                        selected:0,
                        options:[
                            {name:'Female',color:'red',img:'/images/female.svg',value:'F'},
                            {name:'Male',color:'blue',img:'/images/male.svg',value:'M'},
                            {name:'Other',color:'gray',img:'/images/other.svg',value:'T'}
                        ]

                    },
                    location:'',
                    starting_age:35,
                    education:
                        Options({
                            initial:2,
                            names:['Matriculate','Bachelors','Masters+'],
                            values:['C','G','M'],
                            colors:['blue','darkblue','text-color']}
                        )
                    ,
                    marital_status: {selected:1,
                        options:[
                            {name:'Unmarried',color:'blue',img:'/images/single.svg',value:'U'},
                            {name:'Married',color:'red',img:'/images/married.svg',value:'M'},
                        ]
                    },
                    retirement_age:60,
                    city:Options({names:[

                       'Mumbai','Delhi','Chennai','Kolkata',
                       'Bengaluru', 'Hyderabad',
                       'Pune',
                       'Other',
                    ]})
                    ,
                    mother_age: 52,
                    father_age: 56,
                    spouse_age: 31,
                    mother_dependent: false,
                    father_dependent: false,

                    spouse_dependent: YesNo(),
                    children:[ ],

                    occupation_self:
                     Options(
                        {
                            names:['Salaried','Self-Employed','Unemployed'],
                            values:['S','B','U'],
                            colors:['blue','darkblue','text-color']
                        }
                    )
                    /*{
                        selected:0,
                        options:[
                            {name:'Self Employed',color:'blue'},
                            {name:'Salaried',color:'text-color'},
                            {name:'unemployed',color:'blue'},
                        ]
                    }*/
                    ,
                    home_purchase_age:30,
                    annual_income_self:10,
                    annual_income_spouse:0,
                    annual_income_other:0,

                    current_household_expenses:5,
                    current_investment_for_retirement:1,
                    home_area: 1000,
                    home_owned:Options({
                        initial:1,
                        names:['I Rent house','I Own a House'],
                        colors:['red','blue'],
                        values:['N','Y']
                    }),
                    home_loan_availed:0,
                    other_loans_availed:0,
                    // home_loan_availed:Options({
                    //     names:['yes','no'],
                    //     colors:['blue','red'],
                    //     values:['Y','N']
                    // }),
                    // home_loan_amount: 0,
                    // other_loan_amount: 0,
                    total_financial_assets:5,
                    total_real_estate_assets:50,
                    occupation_spouse:
                        Options({
                            names:['Salaried','Self-Employed','Unemployed'],
                            colors:['blue','red','green'],
                            values:['S','B','U']

                        }),
                    goals_home_cost: 1,
                    goals_home_savings: 0,
                    goals_home_year: new Date().getFullYear() + 1,
                    goals_education_cost: 1,
                    goals_education_savings: 0,
                    goals_education_year: new Date().getFullYear() + 1,
                    goals_marriage_cost: 1,
                    goals_marriage_savings: 0,
                    goals_marriage_year: new Date().getFullYear() + 1,
                    goals_holiday_cost: 1,
                    goals_holiday_savings: 0,
                    goals_holiday_year: new Date().getFullYear() + 1,

                    risk_profile: 'M',
                    product_type: 'RG'


            },

            constraints:{
                // a1:'name',
                a2:'gender',
                a3:'city'

            },
            showCreditLinkPopUp: false,
            isReportUnlockd: false
        }
    },
    
    computed:{
        showHouseholdExpenses(){
            return 1;
            // return this.householdIncome == 0 || this.fdata.starting_age>= this.fdata.retirement_age;
        },
        baseUrl(){
            const isRetail = (!this.isLoggedIn && !this.isShareLinkClicked && !(this.$route.path.indexOf('credit') > -1)) ? true : false
            return this.$baseUrl+(isRetail?'bulk/': 'ifa/')
        },
        baseUrlRetail(){
            return this.$baseUrl+'bulk/'
        },
        // apiKey(){
        //     return process.env.VUE_APP_API_KEY
        // },
        plan(){
            return this.$store.state.planData
        },
        householdIncome(){
            const fdata = this.fdata;
            return  fdata.annual_income_self+
                    fdata.annual_income_spouse+
                    fdata.annual_income_other
        },
        isMarried(){
            return this.fdata.marital_status.selected == 1
        },
        isMobile(){
            return screen.width < screen.height
        },
        mainForm(){
            return this.currentSection <= 3;
        },
        progressStyle(){
            return {'width':this.progress+"%"}
        },
        progress(){
            let tp = (this.currentPage+1)/(this.sections[this.currentSection].pages)*100.0;
            tp = Math.min(100,tp);
            return tp;
        },
        formData(){
            const fobj = {};
            const inLakhs = (fname) =>
            ['income','assets','expense','investment','loan_availed','loans_availed'].some(typ => fname.includes(typ))
            Object.keys(this.fdata).forEach(
                k => {
                    try{

                    if(Object.hasOwnProperty.call(this.fdata,k)){
                        const val = this.fdata[k];
                        if((val && typeof(val) == 'object') &&
                         (!Array.isArray(val)) && val.options)
                        {
                            if(val.selected != null){
                                fobj[k] = val.options[val.selected].value;
                            }else{
                                fobj[k] = 'none'
                            }
                        }
                        else if(typeof(val) == 'boolean'){
                            fobj[k] = val?'Y':'N';
                        }
                        else if(!Array.isArray(val)){
                            fobj[k] = val;
                        }
                        // multiply with 100000
                        if(inLakhs(k)){
                            fobj[k] *= 100000;
                        }


                    }
                    }catch (e) {
                        console.error(e)
                    }

                }
            )

            fobj.dependent_children = this.fdata.children.length;
            fobj.dependent_children_ages = this.fdata.children.map(c => c.age).join('|');
            let dpar = 'N';
            if(this.fdata.mother_dependent){dpar = 'M'}
            if(this.fdata.father_dependent){
                if(dpar == 'N'){dpar = 'F'}
                else {dpar = 'B'}
            }
            if(!this.fdata.father_dependent){
                fobj.father_age= 0;
            }
             if(!this.fdata.mother_dependent){
                fobj.mother_age= 0;
            }
            fobj.dependent_parents = dpar;
            
            fobj.location = fobj.city;
            if(fobj.city == 'Other'){
                fobj.location = 'Others'
            }
            // if(fobj.age > 65){
            //     fobj.age = 65;
            //     fobj.retirement_age = 66;
            // }
            fobj.name = fobj.name||'Anonymous'
            fobj.email = 'name@mail.com'
            fobj.mobile =   '9848022338'
            fobj.ifa_name = 'protectmewell'
            if (this.isLoggedIn){
                let str = this.$store.state.user.email;
                     fobj.ifa_name =  this.$store.state.user.name ? (this.$store.state.user.name) : str.split('@')[0]
                     fobj.ifa_email = this.$store.state.user.email
                     fobj.ifa_mobile = this.$store.state.user.mobile
            }
            delete fobj.city;

            return fobj;
        },
        apiUrl(){
            if (!this.isLoggedIn) { // retail
                return  this.baseUrl+this.recUrl+'?'+this.paramString
            } else { //ifa
                return  this.baseUrl+this.recUrl + '/'
            }
        },
        apiUrlRetail(){
            return  this.baseUrlRetail+this.recUrl+'?'+this.paramString
        },
        apiUrlBulk(){
            return  this.baseUrlRetail+this.rec_pdf_Url
        },
        paramString(){
            let str = '';
            try{

                const keys = Object.keys(this.formData);
                for(let i = 0;i< keys.length; i+=1){
                    const key = keys[i]
                    const val = this.formData[key];

                    let valueString = val+'';
                    if(Array.isArray(val)){
                        // valueString = val.join(',');
                    }
                    str += key+'='+valueString;
                    if(i < keys.length-1){
                        str += '&'
                }
            }
            }catch(e){
                console.error(e)
            }
            return str;
        },
        urlParams() {
            const data = {};
            try{
                const keys = Object.keys(this.formData);
                for(let i = 0;i< keys.length; i+=1){
                    const key = keys[i]
                    const val = this.formData[key];
                    data[key] = val;
                }
            }catch(e){
                console.error(e)
            }
            return qs.stringify(data);
        },
        planData(){

            function toNumber(numStr){
                return parseInt((''+numStr).replace(/,/g,''))
            }
            let coverSize = 0
            function prettyAmount(amt){
                const lakh = 100000
                let val =  toNumber(amt)
                coverSize = val
                if(isNaN(val)){return amt}
                if(val<lakh){
                    if(val>1000){
                        const th = Math.floor(val/1000)
                        return '₹'+th+','+(val+'').substr(-3)
                    }
                    else {return '₹'+(val)}
                }
                val/=lakh

                let amtString = "₹"+val.toFixed(2)+" Lakhs"
                if(val>=100){
                    val /= 100;
                    amtString = "₹"+val.toFixed(2)+" Crore"
                    if(val!=1){amtString+='s'}
                }
                return amtString

            }

            if(!this.plan){return {}}
            let plan = {};
            const portfolio = 'recommended_portfolio'

            const termPlan = this.plan[portfolio] ? this.plan[portfolio].tp : ''

            plan.size_of_cover = prettyAmount(termPlan.cover)
            plan.coverValue = coverSize

            plan.plan_duration = termPlan.duration
            plan.term_start = termPlan.start
            plan.plan_duration_text = `you will be
                    ${parseInt(plan.term_start)+parseInt(plan.plan_duration)}
                        years old then.`
            plan.plan_duration += " Years"
            plan.term_start += " Years"

            plan.premium_ns = '₹'+(termPlan.premium_ns)
            plan.premium_s = '₹'+(termPlan.premium_s)

            const prem_ns = toNumber(termPlan.premium_ns)
            const prem_s = toNumber(termPlan.premium_s)

            const income = this.formData.annual_income_self+this.formData.annual_income_other;

            const prem_s_per = (prem_s/income*100).toFixed(2)
            const prem_ns_per = (prem_ns/income*100).toFixed(2)
            plan.premium_s_percent =  prem_s_per+" % of your annual income."
            plan.premium_ns_percent = prem_ns_per+" % of your annual income."

            const allow_reports = this.$store.getters.allow_reports

            plan.record_no = this.plan.record_no
            if(allow_reports){


                const retirement = {...this.plan[portfolio].af}
                const illness =  {...this.plan[portfolio].ci_long}
                const illnessShort =  {...this.plan[portfolio].ci_short}
                const medical= {...this.plan[portfolio].hi}


                retirement.corpus = prettyAmount(retirement.corpus)
                illness.cover = prettyAmount(illness.cover)
                medical.cover = prettyAmount(medical.cover)

                retirement.monthly_investment = prettyAmount(retirement.monthly_investment)

                // const ci_start = parseInt(illness.ci_starting_age)
                // illness.duration = ci_start+' to '+(ci_start+illness.ci_duration)+' Years'
                illness.duration =  illness.duration+' Years'
                medical.duration = 1+' Year'

                plan.retirement_corpus = retirement.corpus+' /-'
                plan.medical_insurance = medical.cover+' /-'
                plan.critical_illness = illness.cover+' /-'
                plan.term_insurance = plan.size_of_cover+(isNaN(plan.coverValue)?'':' /-')

                console.log(plan.retirement)
                plan = {...plan,retirement,illness,medical}
            }


            return plan;

        },
    },
    methods:{
        apiKey(){
            return process.env.VUE_APP_API_KEY
        },
        async accessReport() {
            this.$store.commit('isretail',false);
            if (this.$store.state.creditLinkCredits <= 0) {
               return  this.notification.error({message: 'Insufficient Credits !!'})
            }
            
            window.location.href.indexOf('refferer') > -1
            let dis_code = window.location.href.split('refferer/')[1];

            if (window.location.href.indexOf('credit') > -1 && !this.$store.getters.allow_reports) {
                if (!this.$store.state.user) { // Not logged In
                    this.isShareLinkClicked = true;
                    let apiRequest;
                    apiRequest = this.$axios.post(this.$base_Node_Url +'generic/generatePlanIFA' , {url: this.apiUrl, data: this.formData })
                    const res = await apiRequest
                    const planData = res.data.data
                    if(res){
                        this.loadingAccessReport = true;
                        this.$store.commit('setPlanData',planData)
                        this.$store.commit('setFormData',this.formData)
                        let str = window.location.href.split('credit/')[1];
                        let params = str.split('/');
                        let payload = { creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard}
                        if (this.$store.getters.allow_reports){
                            try {
                                let res = await this.$axios.post(this.$base_Node_Url+'credit-link/checkCreditAndUpdateCreditForPurchaseReport', payload , {headers: {'Content-Type': 'application/json'}})
                                if (res) {
                                    this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: params[0], creditUrl: params[1], isGiftCard: this.$store.state.isGiftCard})
                                    setTimeout(() => {
                                        this.$store.commit('isretail',false);
                                        this.$store.commit('unlockReport');
                                        this.loadingAccessReport = false;
                                        this.showAccessReportPopUp = false;
                                        this.isReportUnlockd = true;
                                    },1000)
                                }
                            }catch(err){
                                this.$error(err)
                            }
                        }
                    }
                }
            }
        },
        purchaseReport() {
            if (this.$store.state.creditLinkCredits <= 0) {
            //    return  this.notification.error({message: 'Insufficient Credits !!'})
            }
            const reportsAllowed = this.$store.getters.allow_reports
            if(!reportsAllowed){
                this.$store.dispatch('showReportUnlockPayment')
            }
        },
        setMaritalOptions(){
            const options = this.mobileMode? ['nopes','of course']:['Unmarried','Married']
            for(let i=0;i<2;i+=1){
                this.fdata.marital_status.options[i].name = options[i]
            }
        },
        async showTheTerms(){
            await this.$wait(200)
            this.showTerms = true;
        },
        hideEmailDialog(){
            this.showEmailDialog = false
        },
        selectCity(k){
            this.fdata.city.selected = k;
            if(k==7){
                this.getLocation();
            }
        },
        geoLocate(){
            return new Promise((res,rej)=>{navigator.geolocation.getCurrentPosition(res,rej)})
        },
        resetForm() {
            for(let k in this.fdata){
                this.fdata[k] = ''
            }
        },
        async getCity(){
            try{
                const result = await this.geoLocate();
                const lat = result.coords.latitude
                const lng = result.coords.longitude
                //
                const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&zoom=12`
                const resp = await this.$axios.get(url);

                const address = resp.data.address
                return address.city || address.county;
            }catch(err){

                if(err.code){
                    let message = 'Timeout'
                    if(err.code == 1){message = 'Please give location permisson'}
                    if(err.code == 2){message = 'Position Unavailable'}

                    throw(message)
                }
                else{
                    throw ('Error getting city name')
                }
            }
        },
        async wait(time){
            return new Promise((res)=>{setTimeout(()=>{res();},time)})
        },
        isEmailValid(email){
            let valid = false;
            const [name,domain] = email.split('@');
            if(name&&domain){
                // const [l,r] = domain.split('.')
                // if(l && r){
                //     valid = true;
                // }
                valid = true;
            }
            return valid;
        },
        async sendEmail(e){
            if(e){e.preventDefault();}
            const emailId =  this.fdata.email;
            localStorage.setItem('emailId',emailId);
            this.$ga.event('email','send','send-email');
            // if(!this.isEmailValid(emailId)){
            //     return this.notification.warning({message:'Enter a valid email ID.'})
            // }
            if(!this.plan){return;}

            var formData = new FormData();
            formData.append('email',emailId);
            formData.append('hash',this.plan.hash);

            /* const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-API-KEY': !this.isLoggedIn ? process.env.VUE_APP_API_KEY : 'private-ifa-access',
                }
            } */
            this.showEmailDialog = false


            try{
                this.sendingEmail = true;
                await this.skipAndDownload(false,false)
                // await this.$axios.post(this.baseUrl+this.email_pdf_Url,formData,config)
                var base_url = this.baseUrlRetail
                if((window.location.href.indexOf('credit') > -1 || this.isLoggedIn ) && this.isReportAllowed()){
                    base_url = this.baseUrlRetail
                }else {
                    base_url = this.baseUrl
                }
                await this.$axios.post(this.$base_Node_Url +'generic/sendReportViaEmail' , {url: base_url+this.email_pdf_Url,'email': emailId, 'hash': this.plan.hash })
                this.sendingEmail = false;
                this.notification.success({message:'Email has been sent'})
                localStorage.setItem('emailId',emailId);

                await this.wait(1000)
                this.$router.push({name:'home'})



            }catch(err){
                this.sendingEmail = false;
                const resData = err.response?err.response.data:null;
                if(resData){
                    // this.notification.error({
                    //     message:(resData&&resData.data&&resData.data[0])
                    //     ||
                    //     resData.statusText
                    // })
                    this.notification.error({message:'Could not send email'})
                }else{
                    this.notification.error({message:'Email could not be sent.Please try later.'})
                }


            }


        },

        async getLocation(){
            try{
                this.loadingLocation = true;
                const fake = false;

                if(fake){
                    await  this.wait(1500)
                }else{
                    const city = await this.getCity();
                    this.fdata.city.options[7].name = city;
                }

                this.loadingLocation = false;
            }catch(err) {
                this.loadingLocation = false;

                const errm = err.response?err.response.data.message:err;
                this.notification.error({message:' '+errm})

            }
        },
        validate(){


        },
        clearPlan(){
            this.$store.commit('setPlanData',null)
        },
       
       
        async generatePlan() {
    this.loadingPlan = true;
    this.$ga.event('plan', 'generate', 'generate plan');

    try {
       

        // Check user data and log it
        console.log('User Data:', this.$store.state.user);
        console.log('isLoggedIn Flag:', this.$store.state.user ? this.$store.state.user.isLoggedIn : 'User object is undefined');
          
 

        // Proceed with generating the plan
        this.clearPlan();
        this.currentPage = 0;
        this.currentSection = 3;
        console.log('Generating plan.');

        // Additional logic for credit link
        if (window.location.href.indexOf('credit') > -1) {
            let str = window.location.href.split('credit/')[1];
            let params = str.split('/');
            const creditLinkRes = await this.$axios.get(`${this.$base_Node_Url}credit-link/getCredit/${params[0]}/${params[1]}/${this.$store.state.isGiftCard}`);

            if (creditLinkRes && creditLinkRes.data) {
                if (creditLinkRes.data.receiver) {
                    this.formData.gift = true;
                    this.formData.name = creditLinkRes.data.receiver.name;
                    this.formData.email = creditLinkRes.data.receiver.emailId;
                    this.formData.ifa_name = '';
                    this.formData.ifa_email = '';
                    this.formData.ifa_mobile = '';
                } else {
                    let name = creditLinkRes.data.created_user_id.email ? creditLinkRes.data.created_user_id.email : '';
                    this.formData.ifa_name = creditLinkRes.data.created_user_id.name ? creditLinkRes.data.created_user_id.name : name.split('@')[0];
                    this.formData.ifa_email = creditLinkRes.data.created_user_id.email ? creditLinkRes.data.created_user_id.email : '';
                    this.formData.ifa_mobile = creditLinkRes.data.created_user_id.mobile;
                }
                this.formData.credit_link = window.location.href;
                this.formData.remaining_credits = this.$store.state.creditLinkCredits - 1;
                this.formData.credit_link_expiry_date = this.$store.state.creditValidity;
            }
        }

        // API request to generate the plan
        const apiUrl = `${process.env.VUE_APP_NODE_API_URL}generic/generatePlanRetail`;
        const apiKey = process.env.VUE_APP_API_KEY;
        const requestData = this.formData;

        const queryString = Object.keys(requestData)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`)
            .join('&');

        const requestUrl = `${process.env.VUE_APP_API_URL}bulk/get_recommendation?${queryString}`;

        const response = await this.$axios.post(apiUrl, { url: requestUrl }, {
            headers: {
                'X-API-KEY': apiKey,
                'Content-Type': 'application/json',
            },
        });

        console.log('API Response:', response);
        const responseData = response.data;

        if (responseData) {
      console.log('Plan generation successful, data:', responseData);
      this.$store.commit('setPlanData', responseData.data);
      this.$store.commit('setFormData', this.formData);

      // Check user data and log it
      const user = this.$store.state.user;
      console.log('User Data:', user);
      console.log('isLoggedIn Flag:', user ? user.isLoggedIn : 'User object is undefined');

      // Check if the user is not logged in
      if (!user) {
        console.log('User not logged in, dispatching showReportUnlockPayment.');
        if (!this.$store.state.reports_unlocked) {
         this.$store.dispatch('showReportUnlockPayment');
        }
        this.showCreditPopUp = false;
        
        return;
      }

      // User is logged in, now check their credits
      const credits = user.credits;
      console.log('Credits:', credits);

      // If credits are 0 or less, lock the button
      if (credits <= 0) {
        console.log('Insufficient credits detected. Locking the button.');
        this.locked = true; // Lock the button
        this.showCreditPopUp = true;
      } else {
        console.log('Sufficient credits detected. Proceeding with credit deduction.');
        // Deduct credits if logged in
        const url = `${this.$base_Node_Url}ifaCreditUse/useCredit`;
        const res1 = await this.$axios.post(url, { 'credits_used': 1 }, { headers: { 'Content-Type': 'application/json' } });

        if (res1) {
          console.log('Credit deducted:', res1);
          this.$store.dispatch('fetchUser');
          setTimeout(() => {
            this.showCreditBalPopUp = true;
          }, 100);
        }

        if (window.location.href.indexOf('credit') > -1 && responseData != null) {
          this.accessReport();
        }
      }
    }

    } catch (err) {
        console.error('Error generating plan:', err);
        this.currentSection = 2;
        this.currentPage = this.sections[this.currentSection].pages - 1;
        if (err.response) {
            const errList = err.response.data.data;
            if (err.response.status === 400) {
                this.notification.error({ message: errList.join(' \n '), duration: 9.5 });
            } else {
                const data = err.response.data;
                const errText = data.message || data.error || err.statusText;
                this.notification.error({ message: errText });
            }
        } else {
            this.notification.error({ message: err.toString() });
        }
    } finally {
        this.loadingPlan = false;
        console.log('Loading finished.');
    }
},





        handleGenerate(){
            if(this.fdata.agree){
                // this.notification.success({message:'plan will be generated'})
                this.generatePlan();
                return true;
            }else{
                // alert('you must accept the terms and conditions')
                this.notification.error({message:'Please agree to Terms and Conditions'});
                return false;
            }
        },
        nameChanged(){
            let newName = this.fdata.name;

            // newName = newName.replace(/[0-9]/g, '');
            newName = newName.replace(/[^a-z^A-Z^ ]+/g,'');
            this.fdata.name  = newName;
            //
        },
        next(){
            this.previousPage = this.currentPage;
            this.currentPage += 1;
            if(this.currentPage >= this.sections[this.currentSection].pages){

                if(this.currentSection < this.sections.length-1){
                    this.currentSection += 1;
                    this.currentPage = 0;
                }else{
                    this.currentPage -= 1;
                    this.notification.warning({message:'under construction'})
                }
            }
        },
        back(){
            if(this.plan){
                this.$store.commit('setPlanData',null)
            }
            if(this.currentSection == 0 && this.currentPage == 0){
                this.$router.push({name:'home'})
            }

            this.currentPage -=  1;
            if(this.currentPage < 0){
                if(this.currentSection>0){
                    this.currentSection -= 1;
                    if(this.currentSection < 0 ){
                        this.currentSection = 0;
                    }
                    this.currentPage = this.sections[this.currentSection].pages-1;
                }else{
                    this.currentPage = 0;
                }
            }

            if(this.currentSection == 3 && !this.plan){
                // const ok = this.handleGenerate();
                // if(!ok){
                this.currentSection = 2;
                this.currentPage = this.sections[this.currentSection].pages-1;
                // }
            }
        },
        skipToGeneratePlan() {
           this.currentPage = this.sections[this.currentSection].pages - 1
           this.skipValues = !this.skipValues;
        }, 
        handleOk(){
            this.showTerms = false;
            this.fdata.agree = true;
        },
        async morePersonal(){
            if(this.plan){
                // this.currentSection = 4;
                await this.$wait(20)
                if (this.isReportAllowed()) {
                  this.showEmailDialog = true;
                } else {
                  this.showEmailDialog = false;
                  if (this.$route.path.indexOf('credit') > -1) { //credit
                      if (!this.$store.state.user) { // logged In
                          this.$emit('show-access-report-popup')
                      }
                  } else if (this.$route.path.indexOf('shortform') > -1) { // shortform 
                          this.$store.dispatch('showPaymentForOneFinanceReport')
                  } else {
                      this.$store.dispatch('showReportUnlockPayment')
                  }
                }
            }
        },
        
        async skipAndDownload(goHome = true, download = true) {
    try {
        // Construct the request body with the URL and hash
        const requestBody = {
          url: `${process.env.VUE_APP_API_URL}bulk/get_pdf_url`,
          hash: this.plan.hash // Assuming this.plan.hash contains the hash value
        };

        // Set the headers for the API request
        const headers = {
            'X-API-KEY': this.apiKey(),
            'Content-Type': 'application/json'
        };
        this.downPDF = download;

         // Determine the base URL
         let base_url = this.baseUrlRetail;
        if ((!this.isLoggedIn && window.location.href.indexOf('credit') === -1) || this.isReportAllowed()) {
            base_url = this.baseUrl;
        }
        
        // Make the API call to your backend endpoint to trigger the process 
        const apiUrl = `${process.env.VUE_APP_NODE_API_URL}generic/downloadReport`;
        const response = await this.$axios.post(apiUrl, requestBody, { headers });

        // Reset download indicator
        this.downPDF = false;

        // Check if the download flag is set to true
        if (download) {
            // Check if the response contains data and pdf_url
            if (response.data && response.data.data && response.data.data.pdf_url) {
                // Create a temporary link element to trigger the download
                const link = document.createElement('a');
                link.href = response.data.data.pdf_url;
                link.download = 'report.pdf';
                link.click();
            } else {
                throw new Error('PDF URL not found in response');
            }
        }

        // Redirect to home page if goHome flag is set to true
        if (goHome) {
            this.$router.push({ name: 'home' });
        }
    } catch (err) {
        // Handle errors
        this.downPDF = false;
        this.notification.error({ message: 'Error downloading PDF' });
        console.error(err);
    }
},

        goToGenerate(first=false){
            if(this.devmode){
                this.waitTime = 0
            }
            if(first){
                this.currentSection=0;
                this.currentPage= 0;
            }else{
                this.currentSection=2;
                this.currentPage=this.sections[this.currentSection].pages-1;
            }
        },
        dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {type:mimeString});
        },
    },
    created(){
        // this.$ga.event({
        //     eventCategory: 'category',
        //     eventAction: 'action',
        //     eventLabel: 'label',
        //     eventValue: 123
        // })

        window.addEventListener('beforeunload',()=>{
            const cs = this.currentSection+1;
            const cp = this.currentPage+1;

            let pages = 0
            for(let i = 0;i< this.currentSection;i +=1 ){
                pages += this.sections[i].length;
            }
            pages += cp;

            let dpar = this.formData.dependent_parents;
            dpar = dpar == 'M'?'Mother':(dpar=='F'?'Father':(dpar=='B'?'Both':'None'))

            let mstat = this.formData.marital_status
            mstat = mstat=='M'?'Married':'Unmarried'
            let age = this.formData.starting_age
            let income = this.formData.annual_income_self
            let gender = this.formData.gender
            gender = gender=='M'?'Male':(gender=='F'?'Female':'Other')

            const fdata = this.formData

            this.$ga.event('user','name',this.formData.name)
            this.$ga.event('user','Gender',gender)
            this.$ga.event('user','Age',age)
            this.$ga.event('user','Retriement age',this.formData.retirement_age)

            this.$ga.event('user','Education',fdata.education)
            this.$ga.event('user','Occupation',fdata.occupation_self)
            this.$ga.event('user','Marital status',mstat)
            this.$ga.event('user','Dependent Parents',dpar)
            this.$ga.event('user','Annual income',income)
            this.$ga.event('user','Location',fdata.location)
            this.$ga.event('user','House',this.formData.home_owned)
            this.$ga.event('user','Home loan',this.formData.home_loan_availed)

            this.$ga.event('user','Home loan amount',this.formData.home_loan_availed)
            this.$ga.event('user','Other loan amount',this.formData.other_loans_availed)


            this.$ga.event('user','Annual income spouse',fdata.annual_income_spouse)
            this.$ga.event('user','Annual income other',fdata.annual_income_other)

            this.$ga.event('user','Total financial assets spouse',fdata.total_financial_assets)
            this.$ga.event('user','Total real estate assets',fdata.total_real_estate_assets)

            this.$ga.event('user','Current household expenses',fdata.current_household_expenses)
            this.$ga.event('user','Current investment for tetirement',fdata.current_investment_for_retirement)

            this.$ga.event('user','Goal for Home Total Goal Amount',fdata.goals_home_cost)
            this.$ga.event('user','Goal for Home Amount Already Saved',fdata.goals_home_savings)
            this.$ga.event('user','Goal for Home Years to Achieve',fdata.goals_home_year)

            this.$ga.event('user','Goal for Kids Total Goal Amount',fdata.goals_education_cost)
            this.$ga.event('user','Goal for Kids Amount Already Saved',fdata.goals_education_savings)
            this.$ga.event('user','Goal for Kids Years to Achieve',fdata.goals_education_year)

            this.$ga.event('user','Goal for Kids Marriage Total Goal Amount',fdata.goals_marriage_cost)
            this.$ga.event('user','Goal for Kids Marriage Amount Already Saved',fdata.goals_marriage_savings)
            this.$ga.event('user','Goal for Kids Marriage Years to Achieve',fdata.goals_marriage_year)

            this.$ga.event('user','Goal for Vacation Total Goal Amount',fdata.goals_holiday_cost)
            this.$ga.event('user','Goal for Vacation Amount Already Saved',fdata.goals_holiday_savings)
            this.$ga.event('user','Goal for Vacation Years to Achieve',fdata.goals_holiday_year)

            this.$ga.event('user','Risk Profile',fdata.risk_profile)
            this.$ga.event('user','Product Type',fdata.product_type)

            // this.$ga.event('form', 'exit','form-exit',pages);
            this.$ga.event('form', 'exit',cs+'-'+cp,pages);
        })
    },
    destroyed(){

    },
    mounted(){
        if (this.$route.path.indexOf('credit') > -1) {
            this.$store.dispatch('getCreditLinkByUrl', {creditLinkId: this.$route.params.creditId, creditUrl: this.$route.params.urlLink, isGiftCard: this.$store.state.isGiftCard })
           this.showCreditLinkPopUp = true;
           if (this.$store.state.user) { // logged In
             this.notification.success({message:'You dont have access to the link'});
             setTimeout(() => {
                this.$router.push({name:'home'})
            }, 1000);
           }
        }
        this.clearPlan();
        this.setMaritalOptions()

        const emailId = localStorage.getItem('emailId')
        this.fdata.email = emailId;
        const city = this.fdata.city;
        city.selected = 4;


        // check for development mode
        this.devmode = process.env.VUE_APP_DEV_MODE == 'TRUE';
        if(this.devmode){
            this.fdata.agree = true;
            document.addEventListener('keydown', (event)=> {
                if(event.ctrlKey){
                    if(event.key == '1') {
                        this.goToGenerate();
                    }
                    else if(event.key == '2') {
                        this.goToGenerate(true)
                    }

                }
            });
        }

    },
    watch:{
        mobileMode(){
            this.setMaritalOptions()
        }

    }


}
</script>

<style>
.print-capture {

  left: 0;
  right: 0;

}

.form-progress-container {
  background-color: lightgray;
  height: 4px;
  width: 100%;
}

.form-progress-bar {
  background-color: var(--scolor);
  background-color: var(--blue);

  height: 100%;
  box-shadow: 0 0 8px var(--scolor);

}

.form-image {
  width: 100%;
}




.fpink {
  background-color: var(--pink);
}

.fblue {
  background-color: var(--blue);
}


.form-checker {
  width: 16px;
  height: 16px;
  border: none;
  background-color: white;
  border: 1px solid steelblue;
}

.city-image {
  width: 64px;
  height: 64px;
  /* height: 100%; */

}

.city-button {
  border: none;
  /* background-color:  ; */

}

.city-button:focus {
  outline: none;

}

.location-input {
  height: 32px;
  background-color: whitesmoke;
  border: 2px solid lightgray;
  outline: none;
}

.location-button {
  color: gray;
  border: 2px solid lightgray;
  padding: 12px;
  margin-left: 12px;
}

.location-icon {
  width: 24px;
}

.double-border {
  border-width: 2px;
}

.normal-button {
  border: 2px solid var(--bluegray);
  padding: 4px 12px;
  background-color: transparent;
  border-radius: 4px;
  color: var(--bluegray);
}

.normal-button:focus {
  outline: none;
}

.normal-button:active {
  box-shadow: inset 0 0 4px gray;

}

.tfade-enter-active,
.tfade-leave-active {
  transition: opacity .2s;
  transition: 0.4s;

}

.tfade-enter,
.tfade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  padding-top: 100vh;
}



.overflow-scrolly {
  overflow-y: auto;
}



.city-tick {
  color: var(--blue);
  text-shadow: 0 0 4px var(--green);
}

.ham-icon {
  width: 24px;
  height: 24px;
}



@media screen and (orientation: landscape) and (min-width:800px) {
  .form-container {
    max-width: 50vh;
    min-width: 470px;
  }

}

@media screen and (orientation: portrait) {
  .hidable-header {
    display: none;
  }
}

.no-shadow,
.no-shadow * {
  box-shadow: unset;
}

.my-form.container {
  max-width: 720px;
}
</style>
